import clsx from "clsx";
import type React from "react";
const SvgComponent = (props: React.SVGProps<SVGSVGElement> & {
  iconOnly?: boolean;
}) => {
  const {
    className,
    iconOnly,
    color,
    ...otherProps
  } = props;
  return <svg xmlns="http://www.w3.org/2000/svg" className={clsx("h-[1em]", className)} {...otherProps} viewBox={iconOnly ? `${769.558 - 101.037} ${0} 101.037 101.037` : "0 0 769.558 101.037"} data-sentry-element="svg" data-sentry-component="SvgComponent" data-sentry-source-file="Logo.tsx">
      <path fill={color ?? "#0c9"} d="M693.533 65.568C674.868 37.582 694.415-.011 727.913 0h26.221c8.52 0 15.424 6.948 15.424 15.519v26.382c-.03 34.91-43.613 51.849-66.03 32.642 44.452-59.04 2.72 11.528-9.995-8.976zm63.686-23.666V15.52a3.094 3.094 0 0 0-3.085-3.104h-26.22c-30.477 0-29.307 29.486-29.307 29.486-.07 6.969 2.085 11.754 5.167 16.698 2.202 3.532 5.553 6.364 9.085 8.567 16.657 10.387 44.334-2.373 44.36-25.265z" data-sentry-element="path" data-sentry-source-file="Logo.tsx" />
      <path fill="none" stroke={color ?? "#0c9"} strokeLinecap="round" strokeLinejoin="round" strokeWidth={10.532} d="m704.297 94.718-29.317.003.015-30.977" data-sentry-element="path" data-sentry-source-file="Logo.tsx" />
      <path fill="#0c9" stroke={color ?? "#0c9"} strokeLinecap="round" strokeLinejoin="round" strokeWidth={12.515} d="m678.87 91.295 46.736-47.18" data-sentry-element="path" data-sentry-source-file="Logo.tsx" />
      <text xmlSpace="preserve" display={iconOnly ? "none" : "inline"} x={670.525} y={99.437} style={{
      fontStyle: "normal",
      fontVariant: "normal",
      fontWeight: 400,
      fontStretch: "normal",
      fontSize: "133.333px",
      fontFamily: "Outfit",
      textAlign: "end",
      letterSpacing: "-3.54px",
      textAnchor: "end",
      strokeWidth: 5.632,
      strokeLinecap: "round",
      strokeLinejoin: "round",
      paintOrder: "markers stroke fill"
    }} data-sentry-element="text" data-sentry-source-file="Logo.tsx">
        <tspan x={662.005} y={99.437} style={{
        fontStyle: "normal",
        fontVariant: "normal",
        fontWeight: 700,
        fontStretch: "normal",
        fontSize: "133.333px",
        fontFamily: "Outfit",
        letterSpacing: "-3.54px",
        stroke: "none"
      }} data-sentry-element="tspan" data-sentry-source-file="Logo.tsx">
          <tspan style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 700,
          fontStretch: "normal",
          fontSize: "133.333px",
          fontFamily: "Outfit",
          letterSpacing: "-3.54px",
          fill: color ?? "#0c9",
          fillOpacity: 1,
          stroke: "none"
        }} data-sentry-element="tspan" data-sentry-source-file="Logo.tsx">
            {"Green"}
          </tspan>
          <tspan style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 700,
          fontStretch: "normal",
          fontSize: "133.333px",
          fontFamily: "Outfit",
          letterSpacing: "-3.54px",
          fill: color ?? "#1b1b1b",
          fillOpacity: 1,
          stroke: "none"
        }} data-sentry-element="tspan" data-sentry-source-file="Logo.tsx">
            {"Links"}
          </tspan>
        </tspan>
      </text>
    </svg>;
};
export type LogoProps<C extends React.ElementType = "span"> = React.ComponentProps<C> & {
  as?: C;
  size?: "large";
  color?: string;
  iconOnly?: boolean;
};
const Logo = <C extends React.ElementType,>(props: LogoProps<C>) => {
  const {
    size,
    iconOnly,
    as,
    color,
    className,
    ...otherProps
  } = props;
  const Tag = as ?? "span";
  return <Tag className={clsx("heading font-bold inline-flex user-select-all", size === "large" ? "text-8xl" : undefined, className)} {...otherProps} data-sentry-element="Tag" data-sentry-component="Logo" data-sentry-source-file="Logo.tsx">
      <SvgComponent iconOnly={iconOnly} color={color} data-sentry-element="SvgComponent" data-sentry-source-file="Logo.tsx" />
    </Tag>;
};
export default Logo;