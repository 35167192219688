import Link from "next/link";
import type React from "react";
import Logo from "src/components/app/Logo/Logo";
import SiteFooter from "src/components/app/SiteFooter/SiteFooter";
import StatusPanel from "src/components/app/StatusPanel";
export interface StatusPageProps {
  children?: React.ReactNode;
  title: string;
}
const StatusPage = (props: StatusPageProps) => {
  const {
    children,
    title
  } = props;
  return <div className={"flex flex-col h-full min-h-[100vh] items-center justify-between"} data-sentry-component="StatusPage" data-sentry-source-file="StatusPage.tsx">
      <title>{title}</title>
      <header className={"flex gap-2 p-10"}>
        <Link href={"/"} data-sentry-element="Link" data-sentry-source-file="StatusPage.tsx">
          <Logo className={"text-4xl self-center"} data-sentry-element="Logo" data-sentry-source-file="StatusPage.tsx" />
        </Link>
      </header>
      <StatusPanel title={title} asChild={true} data-sentry-element="StatusPanel" data-sentry-source-file="StatusPage.tsx">
        <main>{children}</main>
      </StatusPanel>
      <SiteFooter className={"p-10"} data-sentry-element="SiteFooter" data-sentry-source-file="StatusPage.tsx" />
    </div>;
};
export default StatusPage;