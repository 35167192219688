import clsx from "clsx";
import Link from "src/components/common/Link/Link";
export interface SiteFooterProps {
  className?: string;
  hideHelp?: boolean;
}
const SiteFooter = (props: SiteFooterProps) => {
  const {
    className,
    hideHelp
  } = props;
  return <footer className={clsx("flex justify-center items-end", className)} data-sentry-component="SiteFooter" data-sentry-source-file="SiteFooter.tsx">
      <div className={"text-center"}>
        <div className={"text-sm"}>
          <Link href={"/privacy-policy"} data-sentry-element="Link" data-sentry-source-file="SiteFooter.tsx">Privacy Policy</Link>
          <span className={"text-mutedText"}> | </span>
          <Link href={"/cookies"} data-sentry-element="Link" data-sentry-source-file="SiteFooter.tsx">Cookies</Link>
          {hideHelp ? null : <>
              <span className={"mobile:hidden text-mutedText"}> | </span>
              <br className={"desktop:hidden"} />
              <Link href={"/help"}>Help</Link>
            </>}
        </div>
      </div>
    </footer>;
};
export default SiteFooter;